.lading-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1203px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 29px 15px;
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }

  .user {
    margin: 0 27px;
    @media (max-width: 575px) {
      order: 1;
      margin-right: 0;
    }
  }

  .fy-input {
    padding-bottom: 0;
    margin-left: auto;
    max-width: 373px;
    width: 100%;
    @media (max-width: 575px) {
      order: 2;
      margin-top: 30px;
      margin-left: 0;
      max-width: 100%;
    }

    &__wrapper {
      flex-direction: row-reverse;
      border: 1px solid #ffffff8a;
      border-radius: 26px;
      height: 39px;
      padding-right: 20px;

      input {
        font-family: 'RobotoLight';
        color: white;
        font-size: 16px;

        &::placeholder {
          font-family: 'RobotoLight';
          color: white;
          font-size: 16px;
        }
      }
    }
  }
}
