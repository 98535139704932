.user-block {
  @include spaceBetween;

  &_owner {
    @include flexAlignStart;
    flex-direction: row;
    padding: 1.5rem 0;

    &__image-container {
      img {
        height: 8rem;
        width: 8rem;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }

    &__info {
      &-name {
        font-size: 2.5rem;
      }

      &-bio {
        padding: 1rem 0;
      }

      &-tags {

      }

      &-bio, &-tags {
        font-size: 2.2rem;
      }
    }
  }
}