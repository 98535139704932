.feed-owner-info {
  @include spaceBetween;
  padding: 0 0 3rem 1.5rem;

  &_user {
    display: flex;
    justify-content: flex-start;

    &__img-block {
      img {
        height: 8rem;
        width: 8rem;
        border-radius: 50%;
        margin-right: 1rem;
        border: 1px solid grey;
      }
    }

    &__block {
      &-name {
        font-size: 2.5rem;
      }

      &-bio {
        font-size: 2rem;
      }

      &-tags {
        font-size: 2rem;
        padding: 1rem 0;
        @include flexWrap;

        span {
          cursor: pointer;

          &:hover {
            color: #EC3AF5 !important;
          }
        }
      }
    }
  }

  &__menu-dots {
    cursor: pointer;
  }
}

.my-anchor-css-class {
  text-decoration: none;
  color: #4a8b92;
}