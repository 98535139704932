.common-polls-subpage {
  .back-arrow-heading {
    padding: 2.5rem 0 5rem 0;
  }

  .feed-preview {
    padding-bottom: 4rem;
  }

  .no-data__block {
    text-align: center;
    font-size: 3rem;
    padding-top: 3rem;
  }
}