#root {
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;

  .App {
    height: 100%;
  }

  .swipeable-list {
    height: 0;
  }
}