.profile-menu-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 3rem;
  height: 9rem;
  background-color: #292339;
  margin-bottom: 2rem;
  border-radius: 25px;
  cursor: pointer;

  &__icon {
  }

  &__text {
    width: 100%;
    margin-left: 3rem;
    font-size: 2.3rem;
  }

  &__arrow {
  }
}