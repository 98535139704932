.fb-register {
  display: block !important;
  padding: 0 2rem;

  &__tab-block {
    height: 90vh;
  }

  h4 {
    text-align: center;
    width: 100%;
    font-size: 2.9rem;
    font-weight: 100;
    padding-top: 3rem;
  }

  &__footer {
    height: 10vh;
    position: absolute;
    bottom: 0;
    right: 23px;
    left: 23px;
    @include flexCenter;

    img {
      height: 5rem;
      width: 23rem;
    }
  }
}