.step-two-tab {
  h3 {
    font-size: 2.5rem;
    text-align: center;
    padding: 2.5rem 0;
  }

  .image-uploader {
    &__preview {
      height: 4rem;
    }
  }

  &__upload-block {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 6rem;

    .slider {
      width: 100%;

      .slick-slide {
        > div {
          display: flex;
          justify-content: center;
        }
      }
    }

    &__images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      padding-bottom: 3rem;

      .image-component {
        padding: 1rem;

        img {
          height: 16rem;
          width: 12.5rem;
          border-radius: 1.1rem;
          object-fit: cover;
        }
      }
    }
  }
}