@import "login/login.styles";
@import "password-reset/password-reset.styles";
@import "register/register.styles";

.auth-pages {
  >section {
    min-height: 100vh;
    background-color: #08011A;
    @include flexCenter;
  }
}