.hashtags {
  margin-top: 106px;
  &__btn {
    border: 1px solid white;
    font-size: 15px;
    padding: 11px 30px;
    border-radius: 26px;
    font-family: 'RobotoLight';
    display: inline-block;
    margin: 0 17px 18px 0;
  }
}