.fy-button {
  @include flexCenter;
  height: 7rem;
  width: 197px;
  border-radius: 25px;
  border: none;
  background: linear-gradient(90deg, rgba(92, 214, 245, 1) 0%, rgba(236, 58, 245, 1) 100%);

  font-family: RobotoLight;
  font-size: 2.5rem;
  color: white;

  &__body {
    @include flexCenter;

    .spinner {
      padding-left: 0.5rem;
      @include flexCenter;
    }
  }
}