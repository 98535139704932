.checkbox-component {
  .MuiFormControlLabel-label {
    font-size: 2rem;
    line-height: 1.6;
    text-align: left;
  }

  .MuiSvgIcon-root {
    font-size: 5.5rem !important;
  }
}