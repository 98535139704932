.register-success-screen {
  display: flex;
  justify-content: center;
  height: 100vh;

  &__main-block {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &_content {
      position: absolute;
      bottom: 8rem;
      @include flexCenter;
      flex-direction: column;
      width: 100%;

      h3 {
        font-size: 25px;
        text-align: center;
      }

      img {
        height: 45px;
        width: 45px
      }

      > * {
        margin-bottom: 1.5rem;
      }
    }
  }

}