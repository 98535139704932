.ns-modal {
  position: fixed;
  top: 0;
  left: 0;
  @include flex(flex-start, center);
  background-color: rgba(112, 108, 122, 0.5);
  z-index: 9999;
  height: 100vh;
  width: 100%;
  overflow: auto;

  &__overlay {
    @include flexCenter;
    position: relative;
    max-width: 1050px;
    width: 1050px;
    @include modalStyle;
    padding: 3.5rem 1rem;
    margin: 3rem;
    margin-top: 15rem;
    overflow: auto;
    height: 55rem;

    .children {
      max-height: 65vh;
      overflow-x: hidden;
      overflow-y: auto;
      max-width: 100%;
      width: 100%;
    }

    .close {
      position: absolute;
      color: red;
      right: 0.5rem;
      top: 0.5rem;
      font-size: 3rem;
    }
  }

  &__header {
    font-size: 2.5rem;
    text-align: center;
  }

  &__body {
    margin-top: 2.5rem;
  }
}
