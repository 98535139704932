.fy-form {
  padding: 0 2rem;
  text-align: center;
  width: 100%;

  &__heading {
    font-family: RobotoLight;
    font-size: 4.5rem;
    color: #FFFFFF;
    font-weight: 100;
    text-align: center;
    margin-bottom: 0;
  }
}