.password-reset-page {
  &__form {
    display: grid;
    align-content: space-around;
    height: 100vh;
  }

  .fy-form__heading {
    padding-top: 7rem;
  }

  &__button {
    width: 175px;
  }

  &__footer-logo {
    height: 34px;
    width: 160px;
  }
}