// COMMENTS PAGE COMPONENTS STYLES
@import "components/user-block/user-block.styles";

.comments-page {
  @include spaceBetween;
  flex-direction: column;

  .back-arrow-heading {
    padding: 2.5rem 0;
  }

  &__comment-container {
    width: 100%;
    height: 82rem;
    overflow-y: scroll;

    @media only screen and (min-width: 400px) and (max-width: 461px) {
      height: 82rem;
    }

    @media only screen and (min-width: 450px) and (max-width: 650px) {
      height: 80rem;
    }
    @media only screen and (min-width: 650px) and (max-width: 840px) {
      height: 78rem;
    }
    @media only screen and (min-width: 840px) and (max-width: 1100px) {
      height: 68rem;
    }
    @media only screen and (min-width: 1100px) and (max-width: 1200px) {
      height: 66rem;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1300px) {
      height: 53rem;
    }
    @media only screen and (min-width: 1300px) and (max-width: 1500px) {
      height: 51rem;
    }
  }

  &__nocomment-message {
    width: 100%;
    font-size: 3rem;
    text-align: center;
    padding-top: 11rem;
  }
}