.settings-subpage {
  .back-arrow-heading {
    padding: 2.5rem 0;
  }

  &__heading-text {
    padding: 2rem 3rem;
    font-size: 2.5rem;
  }

  &__form {
    padding: 0 !important;

    .fy-select {
      padding-bottom: 3rem;
    }

    &-btn {
      width: 100%;
      height: 6rem;
      border-radius: 0;
    }
  }

  &__logout-btn {
    margin-top: 2rem;
    background: transparent;
    border-radius: 0;
    border: 1px solid white;
    width: 100%;
    height: 6rem;
  }

  &__delete-btn, &__influencer-btn {
    margin-top: 2rem;
    background: transparent;
    border-radius: 0;
    width: 100%;
    height: 6rem;
  }

  &__delete-btn {
    border: 1px solid #d9534f;
    color: #d9534f;
  }

  &__influencer-btn {
    border: 1px solid rgba(92, 214, 245, 1);
    color: rgba(92, 214, 245, 1);
  }
}