.change-password-subpage {
  .back-arrow-heading {
    padding: 2.5rem 0;
  }

  &__form {
    .fy-form__heading {
      padding-bottom: 5rem;
    }
  }
}