.tag-input {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #908795;
    border-radius: 25px;
    padding-left: 1rem !important;
    min-height: 7.5rem;
    height: fit-content;
    @include flexAlignCenter;

    input {
      font-family: RobotoLight;
      font-weight: 100;
      box-sizing: border-box;
      width: 100%;
      background-color: transparent;
      color: #FFFFFF;
      font-size: 2.5rem;
      border: none;

      &::placeholder {
        font-family: RobotoLight;
        font-style: normal;
        font-weight: 100;
        font-size: 2.5rem;
        color: #FFFFFF;
      }

      :focus {
        outline: transparent;
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 8px 0 0;
    }

    .tag {
      width: auto;
      height: 32px;
      @include flexCenter;
      color: #fff;
      padding: 0 8px;
      font-size: 14px;
      list-style: none;
      border-radius: 6px;
      margin: 0 8px 8px 0;
      background-color: #908795;
    }

    .tag-title {
      margin-top: 3px;
    }

    .tag-close-icon {
      display: block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      font-size: 14px;
      margin-left: 8px;
      color: #0052cc;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
    }

    .fy-input {
      padding: 3rem 0 !important;

      &__wrapper {
        border: none;
        padding-left: 0 !important;
        height: 0 !important;
      }
    }
  }

  &__label {
    font-family: RobotoLight;
    font-style: normal;
    font-weight: 100;
    font-size: 2.5rem;
    color: #FFFFFF;
    padding-left: 1.5rem;
    text-align: left;
    padding-bottom: .8rem;
  }
}