.poll-details {
  background-color: #292339;
  border-radius: 4rem;
  padding: 2rem 2rem;

  &__question {
    font-size: 2.2rem;
  }

  &__hashtags {
    font-size: 2.2rem;
    padding: 1rem 0 2rem 0;

    span {
      cursor: pointer;

      &:hover {
        color: #EC3AF5 !important;
      }
    }
  }

  &__slider {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;

    .slider {
      height: 100%;

      .slick-slider {
        max-height: 100%;
        height: 100%;

        .slick-list {
          height: 100%;
          border-top-left-radius: 5rem;
          border-bottom-left-radius: 5rem;
          //border-radius: 4rem;

          .slick-track {
            height: 100%;
            max-height: 100%;

            .slick-slide {
              height: 35rem;

              > div {
                height: 100%;

                .image-component {
                  height: 100%;

                  img {
                    width: 100%;
                    height: 100%;
                    //border-radius: 4rem;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}