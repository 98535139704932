.hashtags-container {
  .hashtags-block {
    @include flexWrap;
    padding-bottom: 2rem;
    padding-top: 2rem;
    justify-content: space-between;

    &__item {
      height: 7rem;
      width: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 2px solid #908795;
      padding: 0 2.2rem;
      margin: 5px 1px;
      cursor: pointer;
      font-size: 2.2rem;
      border-radius: 3rem;
    }

    &__selected-item {
      background-color: #908795;
    }
  }

  &__label {
    font-family: RobotoLight;
    font-style: normal;
    font-weight: 100;
    font-size: 2.5rem;
    color: #FFFFFF;
    padding-left: 1.5rem;
    text-align: left;
    padding-bottom: 0.8rem;
  }
}