// POLL OWNER COMPONENTS STYLES
@import "components/avatar/avatar-block.styles";
@import "components/followers-block/followers-block.styles";
@import "components/poll-slider/poll-slider.styles";

.feed-owner-profile {
  padding: 1rem 0 !important;

  &__no-polls {
    font-size: 2.5rem;
    text-align: center;
    padding-top: 4rem;
  }
}