.custom-picker {

}

.picker-label {
  font-family: RobotoLight;
  font-style: normal;
  font-weight: 100;
  font-size: 2.5rem;
  color: #FFFFFF;
  padding-left: 1.5rem;
  text-align: left;
  padding-bottom: .8rem;
}