// change default styles of corpper js
.cropper-crop-box, .cropper-wrap-box {
  border-radius: 4rem;
}

.cropper-dashed {
  border: none;
}

.cropper-face {
  background-color: transparent;
}
