.step-three-tab {
  &__heading {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h3 {
      font-size: 2.5rem;
      text-align: center;
      padding: 2.5rem 0 0 0;
      width: 100%;
    }
  }

  &__upload-block {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4rem;

    .slider {
      width: 100%;

      .slick-slide {
        > div {
          display: flex;
          justify-content: center;
        }
      }
    }

    &__images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;

      .image-component {
        padding: 1rem;

        img {
          height: 16rem;
          width: 12.5rem;
          border-radius: 1.1rem;
          object-fit: cover;
        }
      }
    }
  }
}

.slider-preview-item {
  height: 16rem;
  width: 12.5rem;
  border-radius: 1.1rem;

  .image-component {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 1.1rem;
    }
  }
}