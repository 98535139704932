.notification {
  .message {
    font-size: 2.5rem
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 3rem;
  }

  .MuiAlert-message {
    font-size: 2rem;
  }

  .MuiAlert-action {
    padding-left: 0;
  }
}

.notifications-item {
  padding: .5rem 0;
}