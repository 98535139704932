.like-block {
  position: relative;
  &__image{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__title{
    font-family: 'Emixvade';
    color: #EC3AF5;
    font-size: 111px;
    line-height: 100px;
    @media (max-width: 575px) {
      font-size: 58px;
      line-height: 70px;
      text-align: center;
    }
  }

  &__subtitle{
    font-size: 29px;
    line-height: 38px;
    @media (max-width: 575px) {
      font-size: 21px;
      line-height: 28px;
      text-align: center;
    }
  }
  &__content{
    position: relative;
    z-index: 0;
    max-width: 1200px;
    margin: 0 auto;
    padding: 182px 15px;
    min-height: 100vh;
  }
}