.private-chat-tab {
  &__item {
    @include flexAlignCenter;
    padding-bottom: 2rem;
    cursor: pointer;

    &-avatar {
      height: 8rem;
      width: 8rem;
      border-radius: 50%;
      margin-right: 2rem;
    }

    &-fullname {
      font-size: 2.5rem;
    }
  }

  &__no-item {
    text-align: center;
    font-size: 4rem;
  }
}