.starter-screen-page {
  padding: 0 !important;
  height: 100% !important;
}

.logo-screen, .middle-screen, .letsgo-screen {
  height: 100vh;
  @include flexContentCenter;
}

.middle-screen, .letsgo-screen {
  img {
    width: 100%;
    object-fit: cover;
  }
}

.logo-screen {
  background-color: #08011A;
  @include flexCenter
}

.letsgo-screen {
  &__button {
    position: absolute;
    bottom: 8rem;
  }
}