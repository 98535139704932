.hashtags-component {
  .hashtags-block {
    @include flexWrap;
    flex-direction: column !important;
    height: 20rem;
    overflow-x: auto;

    &__item {
      height: 45px;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 2px solid #908795;
      border-radius: 25px;
      padding: 0 2.2rem;
      margin: 5px 1px;
      cursor: pointer;
      font-size: 2.2rem;
    }

    &__selected-item {
      background-color: #908795;
    }
  }
}