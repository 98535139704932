.choose-group {
  .fy-input {
    &__wrapper {
      height: 5.5rem;
      border: 2px solid #ffffff;
    }
  }

  &__friends-list {
    .info-block {
      @include flexAlignCenter;
    }

    &__follower {
      padding: 1rem 0;
      @include spaceBetween;

      &_img {
        img {
          height: 8rem;
          width: 8rem;
          border-radius: 50%;
          margin-right: 2rem;
        }
      }

      &_name {
        font-size: 2.5rem;
      }
    }

    .check-button {
      &__item {
        @include flexCenter;
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        border: 2px solid #ffffff;

        path {
          fill: transparent !important;
          stroke: transparent !important;
        }
      }

      &__item-active {
        background-color: white !important;
      }
    }
  }

  .item-active {
    background-color: white !important;

    path {
      fill: rgba(236, 58, 245, 1) !important;
      stroke: rgba(236, 58, 245, 1) !important;
    }
  }
}