.poll-slider {
  padding: 0 2rem;

  .slider {
    .slick-track {
      > div {
        padding: 0 1rem;
      }
    }
  }

  .slider-item {
    .vote-block {
      bottom: 1rem !important;
      right: 2rem !important;
      left: unset;
    }
  }
}